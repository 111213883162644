import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const DashBoard = () => {
    return (
        <>
            <section className='dashboard_section'>
                <div className="container-fluid">
                    <div className="dashboard_area">
                        <div className="top_text_area">
                            <h5>Admin DashBoard</h5>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover m-0">
                                <thead>
                                    <tr>
                                        <th>script name</th>
                                        <th>symbol</th>
                                        <th>ref_high_values_date</th>
                                        <th>day_high</th>
                                        <th>week_high</th>
                                        <th>3_week_high</th>
                                        <th>month_high</th>
                                        <th>3_month_high</th>
                                        <th>6_month_high</th>
                                        <th>12_month_high</th>
                                        <th>all_timw_high</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                    <tr>
                                        <td>Nifty-i</td>
                                        <td>17852</td>
                                        <td>Nifty23022317950ce</td>
                                        <td>32.2</td>
                                        <td>12.59</td>
                                        <td>12.57</td>
                                        <td>12.59</td>
                                        <td>Nifty23022317750pe</td>
                                        <td>29.25</td>
                                        <td>12.4</td>
                                        <td>12.37</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashBoard